import React, { Component } from "react";
import { navigate } from "@reach/router";
import { Link } from "gatsby";
import Error from "src/components/Error";
import { Auth } from "aws-amplify";
import { TextField, Button, Typography, Grid } from "@material-ui/core";
import Layout from "../../Layout";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  formContainer: {
    display: "flex",
    flexDirection: "column",
  },

  input: {
    border: "0",
  },
  label: {
    margin: 0,
    color: "#6C6C6C",
    borderRadius: "0",
    fontSize: 14,
  },
  "&:focus": {
    borderRadius: 0,
  },
});

const initialState = {
  username: ``,
  password: ``,
  email: "",
  phone_number: "",
  authCode: "",
  stage: 0,
  error: "",
};

class Signup extends Component {
  state = initialState;

  handleUpdate = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  signUp = async () => {
    const { username, password, email, phone_number } = this.state;
    try {
      await Auth.signUp({
        username,
        password,
        attributes: {
          email,
          phone_number,
        },
      });
      this.setState({ stage: 1 });
    } catch (err) {
      this.setState({ error: err });
      console.log("error signing up...", err);
    }
  };

  confirmSignUp = async () => {
    const { username, authCode } = this.state;
    try {
      await Auth.confirmSignUp(username, authCode);
      alert("Successfully signed up!");
      navigate("/login");
    } catch (err) {
      this.setState({ error: err });
      console.log("error confirming signing up...", err);
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <Layout>
        <Typography gutterBottom variant="h1">
          Sign Up
        </Typography>

        {this.state.stage === 0 && (
          <Grid container direction="column" spacing={2}>
            {this.state.error && <Error errorMessage={this.state.error} />}
            <Grid item>
              <TextField
                onChange={this.handleUpdate}
                placeholder="Username"
                name="username"
                value={this.state.username}
                inputProps={{
                  className: classes.input,
                }}
                InputLabelProps={{
                  className: classes.label,
                }}
                fullWidth
              />
            </Grid>
            <Grid item>
              <TextField
                onChange={this.handleUpdate}
                placeholder="Password"
                name="password"
                value={this.state.password}
                type="password"
                inputProps={{
                  className: classes.input,
                }}
                InputLabelProps={{
                  className: classes.label,
                }}
                fullWidth
              />
            </Grid>
            <Grid item>
              <TextField
                onChange={this.handleUpdate}
                placeholder="Email"
                name="email"
                value={this.state.email}
                inputProps={{
                  className: classes.input,
                }}
                InputLabelProps={{
                  className: classes.label,
                }}
                fullWidth
              />
            </Grid>
            <Grid item>
              <TextField
                onChange={this.handleUpdate}
                placeholder="Phone Number"
                name="phone_number"
                value={this.state.phone_number}
                inputProps={{
                  className: classes.input,
                }}
                InputLabelProps={{
                  className: classes.label,
                }}
                fullWidth
              />
            </Grid>
            <Grid item>
              <Button onClick={this.signUp}>Sign Up</Button>
            </Grid>
          </Grid>
        )}
        {this.state.stage === 1 && (
          <Grid container direction="column" spacing={2}>
            {this.state.error && <Error errorMessage={this.state.error} />}
            <Grid item>
              <TextField
                onChange={this.handleUpdate}
                placeholder="Authorization Code"
                name="authCode"
                value={this.state.authCode}
                inputProps={{
                  className: classes.input,
                }}
                InputLabelProps={{
                  className: classes.label,
                }}
                fullWidth
              />
            </Grid>
            <Grid item>
              {" "}
              <Button onClick={this.confirmSignUp}>Confirm Sign Up</Button>
            </Grid>
          </Grid>
        )}
        <Link to="/login">Sign In</Link>
      </Layout>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Signup);
