import { makeStyles } from "@material-ui/core";
const styles = makeStyles((theme) => ({
  formContainer: {
    display: "flex",
    flexDirection: "column",
  },

  input: {
    border: "0",
  },
  label: {
    margin: 0,
    color: "#6C6C6C",
    borderRadius: "0",
    fontSize: 14,
  },
  "&:focus": {
    borderRadius: 0,
  },
  active: {
    cursor: "pointer",
  },
}));
export default styles;
