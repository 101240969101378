import { makeStyles } from "@material-ui/core";
const styles = makeStyles((theme) => ({
  h1: {
    fontSize: 24,
    margin: "3.125rem 1.25rem 2.625rem 0",
    display: "inline-block",
  },
  h2: {
    fontSize: 14,
    margin: "0",
    whiteSpace: "nowrap",
    fontWeight: "bold",
    borderBottom: "none",
  },
  tableCell: {
    alignItems: "center",
    borderBottom: "none",
  },
}));
export default styles;
