import React, { useState, useEffect, useCallback } from "react";
import { navigate } from "@reach/router";
import { setUser, isLoggedIn } from "src/utils/auth";
import { Auth } from "aws-amplify";
import { TextField, Button, Typography, Grid } from "@material-ui/core";

import isEmpty from "lodash/isEmpty";
import styles from "./styles";

import FormColumn from "src/components/FormColumn";
import Error from "src/components/Error";
import Analytics from "@aws-amplify/analytics";

const Login = () => {
  const [username, setUsername] = useState(``);
  const [password, setPassword] = useState(``);
  const [error, setError] = useState(``);
  const [newPassword, setNewPassword] = useState(``);
  const [userObj, setUserObj] = useState({});

  const login = async (event) => {
    event.preventDefault();
    try {
      await Auth.signIn(username, password).then((user) => {
        const userInfo = {
          ...user.attributes,
          username: user.username.toLowerCase(),
        };
        setUser(userInfo);
        console.log(user);
        setUserObj(user);

        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        } else {
          navigate("/home");
        }
      });
      Analytics.record("login");
    } catch (err) {
      setError(err.message);
      console.log("error...: ", err);
    }
  };

  const confirmPassword = useCallback(
    (event) => {
      event.preventDefault();
      console.log(userObj);

      const attributes = userObj.challengeParam.userAttributes;
      const email = attributes.email.toLowerCase();
      const phone_number = attributes.phone_number;
      try {
        Auth.completeNewPassword(
          userObj, // the Cognito User Object
          newPassword, // the new password
          // OPTIONAL, the required attributes
          {
            email: email,
            phone_number: phone_number,
          }
        );
      } catch (err) {
        setError(err.message);
        console.log("error...: ", err);
      }
      navigate("/home");
    },
    [newPassword, userObj]
  );

  useEffect(() => {
    if (isLoggedIn()) navigate("/home");
  }, []);

  const classes = styles();

  const isPasswordValid = newPassword.length > 8;
  return (
    <>
      <Typography gutterBottom variant="h1">
        LOGIN
      </Typography>

      {isEmpty(userObj) ? (
        <>
          <form onSubmit={(event) => login(event)}>
            <FormColumn>
              <Grid item>
                <TextField
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder="Username"
                  name="username"
                  value={username}
                  inputProps={{
                    className: classes.input,
                  }}
                  InputLabelProps={{
                    className: classes.label,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item>
                <TextField
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                  name="password"
                  value={password}
                  type="password"
                  inputProps={{
                    className: classes.input,
                  }}
                  InputLabelProps={{
                    className: classes.label,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item>
                <Button type="submit">Login</Button>
              </Grid>
              <Grid item>
                <Grid container justify="space-between">
                  <Grid item>
                    <Typography
                      className={classes.active}
                      onClick={() => navigate("/forgot-password")}
                      variant="caption"
                    >
                      Forgot password
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Typography
                      className={classes.active}
                      onClick={() => navigate("/request-access")}
                      variant="caption"
                    >
                      Request Access
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </FormColumn>
          </form>
        </>
      ) : (
        <>
          <form onSubmit={(event) => confirmPassword(event)}>
            <FormColumn>
              <Grid item>
                <TextField
                  onChange={(e) => setNewPassword(e.target.value)}
                  placeholder="New password"
                  name="newPassword"
                  value={newPassword}
                  type="password"
                  inputProps={{
                    className: classes.input,
                  }}
                  InputLabelProps={{
                    className: classes.label,
                  }}
                  fullWidth
                />
              </Grid>
              {!isPasswordValid && "password must be at least 8 characters"}
              <Grid item>
                <Button disabled={!isPasswordValid} type="submit">
                  Confirm new password
                </Button>
              </Grid>
            </FormColumn>
          </form>
        </>
      )}
      {error && <Error error={error} />}
    </>
  );
};

export default Login;
