import React, { useContext, useMemo } from "react";

import PrivateRoute from "../components/PrivateRoute";
import { Router } from "@reach/router";

import Amplify from "aws-amplify";
import config from "../aws-exports";
import Auth from "@aws-amplify/auth";
import { Account, Checkout, Home } from "../components/Pages";
import Signup from "../components/Pages/Signup";
import Login from "../components/Pages/Login";

import { Context } from "../components/Context";
import OrderInformation from "../templates/OrderInformation";
import kebabCase from "lodash/kebabCase";

Amplify.configure(config);
Auth.configure(config);

const App = () => {
  const { orders, unconfirmedOrders } = useContext(Context);
  const ordersPaths = useMemo(
    () =>
      orders.map((order) => (
        <PrivateRoute
          key={order.id}
          path={`/order/${order.id}`}
          component={OrderInformation}
        ></PrivateRoute>
      )),
    [orders]
  );

  const unconfirmedOrdersPaths = useMemo(
    () =>
      unconfirmedOrders.map((productsArray) => {
        const brand = productsArray[0].brand;
        const path = kebabCase(brand);
        return (
          <PrivateRoute
            key={brand}
            path={`/order/${path}`}
            component={Checkout}
            unconfirmedOrderBrand={brand}
          ></PrivateRoute>
        );
      }),
    [unconfirmedOrders]
  );

  return (
    <Router>
      <PrivateRoute path="/home" component={Home} />
      <PrivateRoute path="/account" component={Account} />
      <PrivateRoute path="/checkout" component={Checkout} />

      {ordersPaths}
      {unconfirmedOrdersPaths}
      <Login path="/login" />
      <Signup path="/signup" />
    </Router>
  );
};

export default App;
