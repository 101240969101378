import React, { useContext, useState, useEffect } from "react";
import { useLocation } from "@reach/router";
import { Context } from "src/components/Context";
import styles from "./styles";

import Column from "src/components/Column";
import { Typography, Grid } from "@material-ui/core";
import ProductsTable from "src/components/ProductsTable";

const OrderInformation = () => {
  const classes = styles();
  const location = useLocation();
  const path = location.pathname;
  const id = path.slice(7);

  const [items, setItems] = useState([]);

  const { orders, getProductsByOrderId } = useContext(Context);

  const order = orders.find((order) => order.id === id);

  const date = new Date(order.updatedAt);
  const dateFirstRow = `${date.getMonth()}.${date.getDay()}.${date.getFullYear()}`;

  useEffect(() => {
    getProductsByOrderId(id, setItems);
  }, [getProductsByOrderId, id]);

  const dateSecondRow = `${date.getHours()}:${date.getMinutes()}`;

  return (
    <>
      <h1 className={classes.h1}>ORDER INFORMATION</h1>
      <Grid container direction="row" wrap="wrap" justify="space-between">
        <Column title="DATE">
          <Typography variant="caption">{dateFirstRow}</Typography>
          <Typography variant="caption">{dateSecondRow}</Typography>
        </Column>
        <Column title="ORDER NUMBER">{order.id}</Column>{" "}
        <Column title="SHIPPING ADRESS"></Column>{" "}
        <Column title="PAYMENT INFORMATION"></Column>
        <Column title="STATUS">{order.status}</Column>
      </Grid>
      <h1 className={classes.h1}>PRODUCTS</h1>

      <ProductsTable filteredProducts={items} isCheckout={false} />
    </>
  );
};
export default OrderInformation;
