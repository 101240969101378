import React, { useEffect } from "react";
import { navigate } from "@reach/router";
import { isLoggedIn } from "../utils/auth";

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  useEffect(() => {
    !isLoggedIn() && navigate(`/login`);
  }, []);

  return <Component {...rest} />;
};

export default PrivateRoute;
